import {ReactElement} from "react";
import "./TopBar.scss"
import linkedin from "./linkedin.png"
import gmail from "./gmail.png"
import github from "./github.png"
import resume from "./resume.png"
import logo from "./logo.png"

export function TopBar(): ReactElement {

    return (
        <div className="top-bar">
            <a href={"https://linkedin.com/in/vibhuiyer"}
               target={"_blank"}>
                <img src={linkedin} alt="" className="social"/>
            </a>
            <a href={"mailto:vibhusiyer@gmail.com?subject=Vibhu Reachout- Website"}
               target={"_blank"}>
                <img src={gmail} alt="" className="social"/>
            </a>
            <a href={"https://github.com/TheVizWiz"}
               target={"_blank"}>
                <img src={github} alt="" className="social"/>
            </a>
            <a href={"https://drive.google.com/file/d/1wWaewaTTbylhwq-WLniYhzHeP83l3Kr6/view"}
               target={"_blank"}>
                <img src={resume} alt="" className="social"/>
            </a>

            <img src={logo} alt={""} className={"social"} style={{
                marginRight: "auto",
                marginLeft: "10px",
            }} onClick={() => {
                window.location.reload();
            }}/>

        </div>
    )
}