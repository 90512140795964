const Theme = {
	surfaceColor: "#121212",
	color1: "#395B64",
	color2: "#A5C9CA",
	color3: "#E7F6F2",
	textColor: "#FFFFFF"
}


export default Theme;
