import Theme from "../../Theme";
import {mouseInfluenceRadius, Point} from "./NameSection";

export function paint(context: CanvasRenderingContext2D | null, points: Point[], wantedPointPositions: Point[], oldWantedPositions: Point[], lines: Point[], oldLines: Point[], mousePos: Point, numSetPoints: number, width: number, height: number, pointSize: number) {


    if (context == null)
        return;



    context.clearRect(0, 0, context.canvas.width, context.canvas.height);
    context.fillStyle = Theme.color2 + "FF";
    for (let p of points) {
        drawPoint(context, p, width, height, pointSize)
    }
    context.lineWidth = 1
    for (const line of lines) {
        paintLine(context,
            points[line[0]],
            points[line[1]],
            wantedPointPositions[line[0]],
            wantedPointPositions[line[1]],
            width, height
        )
    }

    for (const line of oldLines) {
        paintLine(context,
            points[line[0]],
            points[line[1]],
            oldWantedPositions[line[0]],
            oldWantedPositions[line[1]],
            width, height, false
        )
    }

    for (let i = numSetPoints; i < points.length; i++) {
        let point = points[i];
        let mousedx = mousePos[0] - point[0];
        let mousedy = mousePos[1] - point[1];
        let mouseNorm = Math.sqrt(mousedx * mousedx + mousedy * mousedy)

        let mouseDrawRadius = mouseInfluenceRadius / 2;

        if (mouseNorm < mouseDrawRadius) {
            drawLine(context, point, mousePos, width, height, mouseNorm / mouseDrawRadius)
        }
    }

}


function paintLine(
    context: CanvasRenderingContext2D, p1: Point, p2: Point, p1Wanted: Point, p2Wanted: Point, width: number, height: number, backwards = false) {
    let length = Math.sqrt(
        (p2[1] - p1[1]) * (p2[1] - p1[1]) +
        (p2[0] - p1[0]) * (p2[0] - p1[0])
    );

    let p1ErrorSquared = (p1Wanted[0] - p1[0]) * (p1Wanted[0] - p1[0]) + (p1Wanted[1] - p1[1]) * (p1Wanted[1] - p1[1])
    let p2ErrorSquared = (p2Wanted[0] - p2[0]) * (p2Wanted[0] - p2[0]) + (p2Wanted[1] - p2[1]) * (p2Wanted[1] - p2[1])

    let error = Math.sqrt(Math.max(p2ErrorSquared, p1ErrorSquared))


    if (error <= 0.05) {
        let percentage = 0;
        drawLine(context, p1, p2, width, height, (backwards ? (1 - error) : error) * 20);
    }
}

function drawPoint(context: CanvasRenderingContext2D, p: Point, width: number, height: number, pointSize: number) {
    context.beginPath();
    context.arc(p[0] * width, p[1] * height, pointSize / 2, 0, 2 * Math.PI)
    context.closePath();
    context.fill();
}

function drawLine(context: CanvasRenderingContext2D, p1: Point, p2: Point, width: number, height: number, percentage: number) {
    const oldLineWidth = context.lineWidth;
    const oldStrokeStyle = context.strokeStyle;
    const oldFillStyle = context.fillStyle;
    context.lineWidth = 2
    context.strokeStyle = Theme.color2 + Math.round(255 * (1 - Math.sqrt(percentage))).toString(16).padStart(2, "0")

    context.beginPath();
    context.moveTo(p1[0] * width, p1[1] * height);
    context.lineTo(p2[0] * width, p2[1] * height);
    context.strokeStyle = Theme.color2 + Math.round(255 * (1 - Math.sqrt(percentage))).toString(16).padStart(2, "0")
    context.fillStyle = context.strokeStyle
    context.stroke();
    context.closePath();
    context.lineWidth = oldLineWidth
    context.strokeStyle = oldStrokeStyle;
    context.fillStyle = oldFillStyle;
}

