import {ReactElement, useContext, useEffect, useRef, useState} from "react";
import "./NameSection.scss"
import ScrollContext, {ScrollValues} from "../App/ScrollContext";
import Theme from "../../Theme";
import {TopBar} from "../TopBar/TopBar";
import {spot_lines, spot_points} from "../../pictures/spot";
import {drone_lines, drone_points} from "../../pictures/drone";
import {paint} from "./CanvasPainting";
import MainSection from "../MainSection/MainSection";
import up_chevron from "./up_chevron.png"
import {vibhu_lines, vibhu_points} from "../../pictures/vibhu";
import {amazon_lines, amazon_points} from "../../pictures/amazon";
import {weights_lines, weights_points} from "../../pictures/weights";
import {ow2_lines, ow2_points} from "../../pictures/ow2";
import {books_lines, books_points} from "../../pictures/books";
import {game_lines, game_points} from "../../pictures/game";
import {logDOM} from "@testing-library/react";

export type Point = [number, number]
export const mouseInfluenceRadius = 1 / 5;

const border = 0.1;
const NUM_POINTS = 400;

let width = 0, height = 0;
let pointSize = 0.1;
let pointSizeMultiplier = 0.004;
let points: Point[] = [];
let internalPointVelocityRadial: Point[] = [];

let oldFocus: string = "original"
let pointsMap: Map<string,
    {
        wantedPositions: Point[],
        originalPoints: Point[],
        randomPoints?: Point[]
        numPoints?: number,
        text?: string | ReactElement,
        lines: Point[],
    }> = new Map();
let centerBorder = 0.05;
let outer_selected_section = -1;
let outer_current_focus = 0;
let focuses: string[][] = [["vibhu", "books", "weights", "ow2"], ["drone", "spot", "amazon"], ["game", "drone-1"]]

export default function NameSection(): ReactElement {


    let scrollValues = useContext(ScrollContext);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    let previousTime = Date.now();
    let [selectedSection, setSelectedSection] = useState(-1);
    let [currentFocus, setCurrentFocus] = useState(0)

    let sqrt = Math.floor(Math.sqrt(NUM_POINTS))

    let xGap = (1 - 2 * border) / (sqrt - 1);
    let yGap = (1 - 2 * border) / (sqrt - 1)


    useEffect(() => {
        const canvas = canvasRef.current
        if (canvas == null)
            return
        const context = canvas.getContext('2d')
        if (context == null)
            return;
        context.fillStyle = Theme.color2 + "00"
        width = canvas.clientWidth;
        height = canvas.clientHeight;
        if (canvas.width !== width || canvas.height !== height) {
            canvas.width = width;
            canvas.height = height;
        }

        pointSize = Math.min(width, height) * pointSizeMultiplier


        window.onresize = () => {
            calculatePointPositions(canvas)
            pointSize = Math.min(width, height) * pointSizeMultiplier
        }

        let mousePos: Point = [canvas.width / 2, canvas.height / 2]
        canvas.onmousemove = ctx => {
            mousePos = [ctx.x / canvas.clientWidth, ctx.y / canvas.clientHeight]
        }

        for (let i = 0; i < NUM_POINTS; i++) {
            let x = Math.random(), y = Math.random();
            points.push([x, y]);
            internalPointVelocityRadial.push([Math.random() * Math.PI * 2, 0.05])
        }

        let wantedPointPositions: Point[] = []


        for (let i = 0; i < NUM_POINTS; i++) {
            let x = i % sqrt;
            let y = Math.floor(i / sqrt);

            wantedPointPositions.push([x * xGap + border,
                y * yGap + border])

        }

        wantedPointPositions = shuffle(wantedPointPositions)

        pointsMap.set("original", {
            originalPoints: wantedPointPositions.slice(),
            wantedPositions: wantedPointPositions.slice(),
            lines: []
        })


        pointsMap.set("vibhu", {
            originalPoints: vibhu_points.slice(),
            wantedPositions: vibhu_points,
            lines: vibhu_lines,
            text: (<div>
                <h2>Hey, I'm Vibhu!</h2>
                and welcome to my website! I'm a student at Rutgers University,
                double majoring in Computer Science and Mechanical Engineering,
                with a minor in Math - and an aspiring PhD student in robotics. Here are a few things about
                me:
            </div>)
        })

        pointsMap.set("books", {
            originalPoints: books_points.slice(),
            wantedPositions: books_points,
            lines: books_lines,
            text: "I love to read! Most of what I read is science fiction and fantasy. " +
                "You'll always find me waiting for the bus with a book in my hand, or sneaking a couple pages in between classes. " +
                "My current favorite authors include Brandon Sanderson, Patrick Rothfuss, Robert J. Crane, and Brian Staveley - " +
                "I love my sprawling, epic sagas and journeys. If you have any suggestions, let me know!"
        })


        pointsMap.set("weights", {
            originalPoints: weights_points.slice(),
            wantedPositions: weights_points,
            lines: weights_lines,
            text: "When I'm not reading - and sometimes, even when I am! - you'll find me at the gym. " +
                "I've been an avid gym rat for about 3 years now, and am secretly, unfortunately, addicted to working out shoulders. " +
                "If you ever see me shoulder pressing 80 pounds a hand on a supposed arms or pull day, just turn around and pretend you didn't."
        })

        pointsMap.set("ow2", {
            originalPoints: ow2_points.slice(),
            wantedPositions: ow2_points,
            lines: ow2_lines,
            text: "At the end of the day, my friends and I like to relax by playing video games together, " +
                "a ritual that started during the long, online days of the pandemic. " +
                "My claim to fame is being #206 in the world in Halo, and #492 in Overwatch. These days, " +
                "we've also turned to the more sophisticated forms of entertainment - by which, of course, I mean Monopoly."
        })


        pointsMap.set("spot", {
            originalPoints: spot_points.slice(),
            wantedPositions: spot_points,
            lines: spot_lines,
            text: "I worked on an undergraduate thesis under Dr. Xi Gu, where we tried to improve the way that robot dogs walk on rough and varied terrain. " +
                "We did this by calculating the position of the center of gravity, and how it moves when the robot walks, and try to make sure that the center of gravity stays " +
                "as stable as possible, using the body of the robot itself as counterweight."
        })


        pointsMap.set("drone", {
            originalPoints: drone_points.slice(),
            wantedPositions: drone_points,
            lines: drone_lines,
            text: "Currently, I do research at Rutgers under Professor Jingjin Yu. Using my background in both CS and mechanical engineering, " +
                "I lead a project where we build small drones that can also charge wirelessly and drive. " +
                "We plan on using these drones to optimize scheduling algorithms in 3D, a problem known as " +
                "multi-robot motion-planning (MRMP). The project - and I personally - have actually been NSF REU funded!"
        })

        pointsMap.set("amazon", {
            originalPoints: amazon_points.slice(),
            wantedPositions: amazon_points,
            lines: amazon_lines,
            text: "This summer (2024) and the summer of 2023, I interned at Amazon, where I built internal tooling to improve efficiency " +
                "when building websites for our branch. " +
                "I built an abstraction layer on top of CDK, the Amazon infrastructure system. " +
                "You might even use my code to build your own tooling dashboards once (if*) it is merged " +
                "into CDK itself!"
        })


        pointsMap.set("game", {
            originalPoints: game_points.slice(),
            wantedPositions: game_lines,
            lines: game_lines,
            text: "I'm actually working on my own video game! " +
                "It's called \"Edgewalker\", a metroidvania-style 2D game about a creature made of the essence of dark and light, " +
                "trying to figure out why the gods of the realm have forsaken it. " +
                "I don't want to spoil too much - go play it when it comes out!"
        })


        pointsMap.set("drone-1", {
            originalPoints: drone_points.slice(),
            wantedPositions: drone_points,
            lines: drone_lines,
            text: "I've always wanted to build a drone, and last summer, I decided to actually try. " +
                "I built a fully-functioning ESP32 drone from the ground up, and decided to do everything myself - " +
                "from the PCB design, to the CAD, to all the code too! I can't say it works too well, " +
                "but I did manage to make it do a flip and not crash, which is kinda all I wanted in the first place anyways; " +
                "at least, that's what I tell myself."
        })

        pointsMap.forEach((value, key) => {
            value.numPoints = value.originalPoints.length;
            value.randomPoints = [];
            for (let i = 0; i < NUM_POINTS; i++) {
                value.randomPoints.push([Math.random(), Math.random()])
            }
        })


        calculatePointPositions(canvas)


        console.log("starting values: ", selectedSection, currentFocus)


        function frame() {
            movePoints(context!, mousePos, points,
                outer_selected_section !== -1 ?
                    pointsMap.get(focuses[outer_selected_section][outer_current_focus])!.wantedPositions :
                    pointsMap.get("original")!.wantedPositions,
                previousTime);
            console.log()
            let numSetPoints = outer_selected_section != -1 ? pointsMap.get(focuses[outer_selected_section][outer_current_focus])?.originalPoints.length : 0
            if (!numSetPoints)
                numSetPoints = 0;
            paint(context,
                points,
                outer_selected_section !== -1 ?
                    pointsMap.get(focuses[outer_selected_section][outer_current_focus])!.wantedPositions :
                    pointsMap.get("original")!.wantedPositions,
                outer_selected_section !== -1 ?
                    pointsMap.get(oldFocus)!.wantedPositions :
                    pointsMap.get(oldFocus)!.wantedPositions,
                outer_selected_section !== -1 ?
                    pointsMap.get(focuses[outer_selected_section][outer_current_focus])!.lines :
                    pointsMap.get("original")!.lines,
                outer_selected_section !== -1 ?
                    pointsMap.get(oldFocus)!.lines :
                    pointsMap.get(oldFocus)!.lines,
                mousePos,
                numSetPoints,
                width,
                height,
                pointSize
            )
            previousTime = Date.now();
            window.requestAnimationFrame(frame);
        }

        window.requestAnimationFrame(frame)

    }, [])

    useEffect(() => {
        switchFocus(0);
    }, [selectedSection]);


    function switchSection(sectionNumber: number) {
        setSelectedSection(old => sectionNumber)
        switchFocus(0)
        outer_selected_section = sectionNumber;
    }


    function switchFocus(new_focus: number) {
        if (selectedSection == -1) {
            return;
        }
        if (focuses[selectedSection].length <= new_focus)
            return;
        if (new_focus < 0)
            return;

        oldFocus = focuses[outer_selected_section][outer_current_focus];
        setCurrentFocus(old => new_focus)
        outer_current_focus = new_focus;
    }


    return (
        <div className={"name-section"} style={{
            backgroundImage: `linear-gradient(${scrollValues.currentPercentage * 100 - 90}deg, 
						 ${Theme.color1}, ${Theme.surfaceColor})`,
        }}>

            <canvas className={"backgroundCanvas"} style={{
                width: "100%",
                height: "100%"
            }} ref={canvasRef}>
            </canvas>
            <TopBar/>
            <div className={"title-box " + (selectedSection !== -1 ? "" : "title-box-unclicked")}>
                <div className={"name " + (selectedSection !== -1 ? "" : "name-unclicked")}>
                    {/*{window.innerWidth <= 800 ? (<>Vibhu<br/>Iyer</>) : "Vibhu Iyer"}*/}
                    Vibhu Iyer
                </div>


                <div className={"sections-box"}>
                    <div
                        className={"section-select-button " + (selectedSection === 0 ? "section-select-button-selected" : "")}
                        onClick={() => {
                            switchSection(selectedSection === 0 ? -1 : 0)
                        }}>
                        Profile
                    </div>
                    <div
                        className={"section-select-button " + (selectedSection === 1 ? "section-select-button-selected" : "")}
                        onClick={() => {
                            switchSection(selectedSection === 1 ? -1 : 1)

                        }}>
                        Experience
                    </div>
                    <div
                        className={"section-select-button " + (selectedSection === 2 ? "section-select-button-selected" : "")}
                        onClick={() => {
                            switchSection(selectedSection === 2 ? -1 : 2)
                        }}>
                        Projects
                    </div>
                </div>
            </div>
            {
                selectedSection !== -1 ? <>
                    <MainSection
                        text={selectedSection !== -1 ? pointsMap.get(focuses[selectedSection][currentFocus])?.text || focuses[selectedSection][currentFocus] : "nothing"}/>
                    {currentFocus !== 0 ?
                        <img src={up_chevron}
                             alt="Previous Section"
                             className="upTriangle triangle"
                             onClick={() => switchFocus(currentFocus - 1)}/>
                        : <></>
                    }

                    {currentFocus !== focuses[selectedSection].length - 1 ?
                        <img src={up_chevron}
                             alt="Previous Section"
                             className="downTriangle triangle"
                             onClick={() => switchFocus(currentFocus + 1)}/>
                        : <></>
                    }
                </> : <></>
            }
        </div>
    )
}


function shuffle(array: any[]) {
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex > 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }

    return array;
}


function movePoints(context: CanvasRenderingContext2D, mousePos: Point, points: Point[], wantedPositions: Point[], previousTime: number) {
    if (context === null)
        return;
    const pixelsPerSecond = 1.5;
    const mouseForce = 0.00001;
    const MAX_DIRECTION_CHANGE = 0.0005;
    const dt = (Date.now() - previousTime) / 1000.0;
    const boundaryLimit = 0.1;

    let numSetPoints = outer_selected_section != -1 ? pointsMap.get(focuses[outer_selected_section][outer_current_focus])?.originalPoints.length : 0
    if (!numSetPoints)
        numSetPoints = 0;


    for (let i = 0; i < points.length; i++) {
        internalPointVelocityRadial[i][0] += (0.5 - Math.random()) * MAX_DIRECTION_CHANGE * dt;
    }

    for (let i = 0; i < points.length; i++) {
        let point = points[i];
        let wantedPoint = wantedPositions[i];

        if (wantedPoint === undefined)
            continue

        let dx = wantedPoint[0] - point[0];
        let dy = wantedPoint[1] - point[1];
        let vNorm = Math.sqrt(dx * dx + dy * dy)
        // let vNorm = 50;
        let velocity = [dx, dy]

        velocity[0] = (outer_selected_section !== -1 && i > numSetPoints)
            ?
            Math.cos(internalPointVelocityRadial[i][0]) * internalPointVelocityRadial[i][1]
            : velocity[0] * pixelsPerSecond;
        velocity[1] = (outer_selected_section !== -1 && i > numSetPoints)
            ?
            Math.sin(internalPointVelocityRadial[i][0]) * internalPointVelocityRadial[i][1]
            : velocity[1] * pixelsPerSecond;


        let mousedx = mousePos[0] - point[0];
        let mousedy = mousePos[1] - point[1];
        let mouseNorm = Math.sqrt(mousedx * mousedx + mousedy * mousedy)
        let dMouse = [mousedx / mouseNorm / mouseNorm, mousedy / mouseNorm / mouseNorm]


        point[0] += velocity[0] * dt
        point[1] += velocity[1] * dt

        if (mouseNorm <= mouseInfluenceRadius) {
            point[0] += -dMouse[0] * mouseForce
            point[1] += -dMouse[1] * mouseForce
        }

        if (outer_selected_section !== -1 && i > numSetPoints) {
            const leftdx = point[0];
            const rightdx = 1 - point[0];
            const topdx = point[1];
            const botdx = 1 - point[1];

            if (topdx < boundaryLimit && Math.sin(internalPointVelocityRadial[i][0]) < 0)
                internalPointVelocityRadial[i][0] = -internalPointVelocityRadial[i][0];
            if (botdx < boundaryLimit && Math.sin(internalPointVelocityRadial[i][0]) > 0)
                internalPointVelocityRadial[i][0] = -internalPointVelocityRadial[i][0];


            if (leftdx < boundaryLimit && Math.cos(internalPointVelocityRadial[i][0]) < 0)
                internalPointVelocityRadial[i][0] = internalPointVelocityRadial[i][0] + Math.PI;

            else if (rightdx < boundaryLimit && Math.cos(internalPointVelocityRadial[i][0]) > 0)
                internalPointVelocityRadial[i][0] = internalPointVelocityRadial[i][0] + Math.PI;
            // point[1] += ((topdx < boundaryLimit ? topdx : 0)) * dt * boundaryForce;
        }

        point[0] = point[0] % 1;
        point[1] = point[1] % 1;

    }


}


function calculatePointPositions(canvas: HTMLCanvasElement) {
    let topLeft: Point = [0.5 + centerBorder, 0.3]
    let bottomRight: Point = [1 - border, 1 - border]
    let phoneDim: Point = [0.8, 0.3]

    if (canvas.clientWidth <= 800) {
        topLeft = [0.1, 0.6]
        bottomRight = [topLeft[0] + phoneDim[0], topLeft[1] + phoneDim[1]]
    }

    let centerFrame: Point = [(topLeft[0] + bottomRight[0]) / 2, (topLeft[1] + bottomRight[1]) / 2]
    let frameSize: Point = [bottomRight[0] - topLeft[0], bottomRight[1] - topLeft[1]]


    let frameWidthPixels = (bottomRight[0] - topLeft[0]) * canvas.clientWidth;
    let frameHeightPixels = (bottomRight[1] - topLeft[1]) * canvas.clientHeight;

    let widthMultiplier = 1;
    let heightMultiplier = 1;

    if (frameWidthPixels > frameHeightPixels) {
        widthMultiplier = frameHeightPixels / frameWidthPixels;
    } else {
        heightMultiplier = frameWidthPixels / frameHeightPixels;
    }

    pointsMap.forEach((object, key) => {
        let new_points = object.originalPoints.slice()
        let new_lines = object.lines
        let new_wanted_positions = new_points

        let scale = key !== "original"
        for (let i = 0; i < new_wanted_positions.length; i++) {
            new_wanted_positions[i] = scale ? [
                (0.5 - new_wanted_positions[i][0]) * -widthMultiplier * frameSize[0] + centerFrame[0],
                (0.5 - new_wanted_positions[i][1]) * -heightMultiplier * frameSize[1] + centerFrame[1],
            ] : new_wanted_positions[i]
        }

        for (let i = 0; i < NUM_POINTS; i++) {
            if (i > object.numPoints!) new_wanted_positions.push(object.randomPoints![i])
        }

        object.lines = new_lines
        object.wantedPositions = new_wanted_positions
    })

}










