import {createContext} from "react";

const ScrollContext = createContext({
	currentPercentage: 0,
	totalLength: 1,
});


export default ScrollContext

export let ScrollValues = {
	currentPercentage: 0,
	totalLength: 1
}
