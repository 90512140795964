import {createContext, ReactElement, useEffect, useState} from "react";
import "./app.scss"
import ScrollContext, {ScrollValues} from "./ScrollContext";
import NameSection from "components/NameSection/NameSection";
// import {arm} from "../../RobotArm";

export default function App (): ReactElement {

	let [[currentPercentage, totalLength], setNewScrollValue] = useState([document.body.scrollHeight - document.body.offsetHeight, 0]);

	useEffect(() => {
		document.onscroll = (context) => {
			setNewScrollValue((currentVal) => {
				totalLength = document.body.scrollHeight - document.body.offsetHeight;
				ScrollValues.totalLength = totalLength;
				ScrollValues.currentPercentage = (document.documentElement.scrollTop || document.body.scrollTop) / totalLength;
				return [(document.documentElement.scrollTop || document.body.scrollTop) / totalLength, totalLength]
			});
		};
	}, []);


	return (
		<div>
			<div className={"app"}>
				<NameSection/>
			</div>
		</div>
		// <ScrollContext.Provider value={{
		// 	currentPercentage: currentPercentage,
		// 	totalLength: totalLength,
		// }}>
		//
		// 	<div className={"hiddenScroll"}>
		// 	</div>s
		// </ScrollContext.Provider>
	)

}


