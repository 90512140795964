import {ReactElement} from "react";
import "./MainSection.scss"

export default function MainSection(props: {
    text: string | ReactElement
}): ReactElement {


    return (
        <div className={"main-section"}>
            {props.text}
        </div>
    )
}